html {
  font-size: 18px;
}

.cover-container {
  background-image: url('./public/imgs/cover-img.jpg');
  padding: 2.5rem 2rem;
}

/* Popup style */
.box {
  width: 30%;
}

.close-icon {
  /* (100-30)/2 - half width: */
  right: calc(35% - 1rem);
  top: calc(30vh - 1rem);
}

@media (max-width: 2000px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1300px) {
  html {
    font-size: 15px;
  }

  .box {
    width: 40%;
  }

  .close-icon {
    /* (100-40)/2 - half width: */
    right: calc(30% - 1rem);
    top: calc(30vh - 1rem);
  }
}

@media (max-width: 900px) {
  html {
    font-size: 11px;
  }

  .box {
    width: 60%;
  }

  .close-icon {
    /* (100-60)/2 - half width: */
    right: calc(20% - 1rem);
    top: calc(30vh - 1rem);
  }
}

@media (max-width: 600px) {
  .cover-container {
    background-image: url('./public/imgs/cover-img-for-small-screens.jpg');
    padding: 2.0rem 2rem;
  }

  html {
    font-size: 11px;
  }
}

:root {
  --bg-1: #111214;
  --bg-2: #1B1E24;
  --bg-3: #2D2F33;

  --text-color-1: #FFFFFF;
  --text-color-2: #ACACAC;
  --text-color-3: #828282;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-2);
  color: var(--text-color-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}


/*---------- Toshi's Code Start ----------*/
.main-wrapper {
  /*max-width: 1920px;
  padding: 0 140px;*/
  margin-inline: auto;
}

/* Banner Container - see above media@ for some parameters */
.cover-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 10rem;
  max-height: max-content;
  width: 100%;
  margin-top: 2rem;
  border-radius: 1rem;
  background-color: #000000;
  -webkit-box-shadow: 0px 40px 25px -30px rgba(0, 0, 0, 0.60);
  -moz-box-shadow: 0px 40px 25px -30px rgba(0, 0, 0, 0.60);
  box-shadow: 0px 40px 25px -30px rgba(0, 0, 0, 0.60);
  background-repeat: no-repeat;
  background-size: auto 100%;
  opacity: 100%;

  background-position: top right;
}

.section-1 .header {
  color: var(--text-color-1);
  font-size: 2rem;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.section-1 .desc {
  color: var(--text-color-2);
  font-size: 2rem;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  margin-top: 0.5rem;
  width: 75%;
  margin-bottom: 0rem;
}

/* Banner Container */
.bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: max-content;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  padding-bottom: 1rem;
  background-color: #000000;
  -webkit-box-shadow: 0px 40px 25px -30px rgba(0, 0, 0, 0.60);
  -moz-box-shadow: 0px 40px 25px -30px rgba(0, 0, 0, 0.60);
  box-shadow: 0px 40px 25px -30px rgba(0, 0, 0, 0.60);
  font-size: 1rem;
}

.bottom-container ul {
  display: flex;
  margin: 0 0 0.5em 0;
  padding: 0;
  color: var(--text-color-3) !important;
}

.bottom-container ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.bottom-container ul li img {
  height: 1.1rem;
  width: auto;
}


/* Player Stats */
.stats-wrapper {
  margin-top: 2rem;
}

.stats-wrapper>.header {
  font-size: 1.5rem;
  color: var(--text-color-2);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 2fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.card-1 {
  color: var(--text-color-2);
  background-color: var(--bg-3);
  border-radius: 1.3rem;
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem;
  gap: 2rem;
  font-size: 1rem;
}

.card-1 .card-title {
  font-size: 1.2rem;
}

.card-1 .card-body span {
  font-size: 1.6rem;
  color: var(--text-color-1) !important;
}

.card-2 {
  color: var(--text-color-2);
  background-color: var(--bg-3);
  border-radius: 1.3rem;
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem;
  gap: 1rem;
  font-size: 1rem;
}

.card-2 .card-title {
  font-size: 1.2rem;
}

.card-2 .card-body span {
  font-size: 1.6rem;
  color: var(--text-color-1) !important;
}

.stats-next-game {
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  font-size: 1.2rem;
  color: var(--text-color-1);
}



/* Charts Container */
.charts-wrapper {
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.chart-contianer {
  flex: 1;
  color: var(--text-color-3);
  background-color: var(--bg-3);
  border-radius: 1.3rem;
  padding: 1.2rem;
}

.chart-contianer .header {
  font-size: 1rem;
  margin-bottom: 0rem;
  color: var(--text-color-2);
}

/* Popup style - some of it defined at @media*/
.popup-box {
  position: fixed;
  background: #000000a1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  margin: 0 auto;
  height: auto;
  margin-top: calc(30vh - 0px);
  background: var(--bg-2);
  border-radius: 1rem;
  padding: 1.7rem;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  background: var(--bg-3);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  line-height: 1.6rem;
  text-align: center;
  border: 1px solid #999;
  font-size: 1.5rem;
}

.input-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-text {
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
  background: var(--bg-3);
  color: var(--text-color-1);
  border: 1px solid #999;
  border-radius: 0.3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
}

.input-text::placeholder {
  color: var(--text-color-1);
}

.input-text-multiline {
  width: 100%;
  height: 6rem;
  margin-bottom: 1rem;
  background: var(--bg-3);
  color: var(--text-color-1);
  border: 1px solid #999;
  border-radius: 0.3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
}

.input-text-multiline::placeholder {
  color: var(--text-color-1);
}

.button-submit {
  width: 5rem;
  height: 2rem;
  color: var(--text-color-1);
  background: var(--bg-3);
  border-radius: 0.3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}